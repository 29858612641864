<template>

  <v-container
    fill-height>

    <v-row>
      <v-col>

        <v-card style="max-width: 800px;">
          <v-card-title>
            Enter Phone
          </v-card-title>

          <v-alert class="mx-8" v-if="error" type="error">
            {{ error }}
          </v-alert>

          <v-card
            class="d-flex flex-wrap flex-row justify-center"
            width="100%"
            style="display: grid; grid-auto-columns: 10%;">

            <div
              class="text-sm-h5">
              {{ numbers.join('') | VMask('(###) ###-####') }}
            </div>

          </v-card>

          <v-card style="display: grid; grid-template-columns: 1fr 1fr 1fr;">
            <v-btn
              style="box-sizing: border-box"
              class="ma-3"
              height="100"
              v-for="(item, index) in Array(count-1)"
              :key="index"
              :disabled="numbersFull"
              @click="digit(index+1)">
              {{ index+1 }}
            </v-btn>
            <v-btn
              height="100"
              class="ma-3"
              :disabled="numbersEmpty"
              @click="clear">
              <v-icon>mdi-close-circle-outline</v-icon>
            </v-btn>
            <v-btn
              height="100"
              class="ma-3"
              :disabled="numbersFull"
              @click="digit(0)">
              0
            </v-btn>
            <v-btn
              height="100"
              class="ma-3"
              :disabled="numbersEmpty"
              @click="backspace">
              <v-icon>mdi-backspace-outline</v-icon>
            </v-btn>

            <v-btn
              justify="right"
              height="100"
              style="grid-column: 2;"
              class="ma-3"
              :disabled="!numbersFull"
              @click="submit">
              Submit
            </v-btn>
          </v-card>

        </v-card>
      </v-col>
    </v-row>

  </v-container>

</template>
<script>
import { mapActions } from 'vuex'

export default {

  props: {
    prefix: {
      type: String,
      default: '649',
    },

    count: {
      type: Number,
      default: 10
    },
  },

  data() {
    return {
      numbers: [...this.prefix.split(''), ...Array(this.count - this.prefix.length)],
      error: undefined,
    }
  },

  created() {
    console.log(this.numbers)
  },

  computed: {
    numbersFull() { return this.numbers.findIndex(n => !n) == -1 },
    numbersEmpty() { return this.numbers.findIndex(n => !n) == 0 },
  },

  methods: {
    ...mapActions('Auth', ['authPhone']),

    submit() {

      this.authPhone(this.numbers.join(''))

      .then(function(res) {
        debugger
        const to = res?.data
        this.$router.push({ name: 'enter-pin', params: { phone: to } })
      }.bind(this))

      .catch(function(error) {
        this.error = error.response.data?.underlyingError?.message ?? error.response?.data?.message ?? 'Unknown error'
      }.bind(this))
    },

    backspace() {
      let lastIndex = this.numbers.findIndex(n => !n)

      if (lastIndex == -1) {
        // At end, set lastIndex to count
        lastIndex = this.numbers.length
      }

      this.numbers[lastIndex-1] = undefined
      this.numbers = [...this.numbers]
    },

    clear() {
      this.numbers = Array(this.count)
    },

    digit(number) {
      number = number.toString()

      let lastIndex = this.numbers.findIndex(n => !n)

      if (this.numbersFull) return

      this.numbers[lastIndex] = number
      this.numbers = [...this.numbers]

      if (this.numbersFull) return
    }
  },
}

</script>
